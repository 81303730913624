.title-layout{
  .title {
    font-size: 27px;
    font-weight: 100;
  }
}

.login-title {
  font-size: 20px;
  font-weight: 300;
}

.QR-code {
  width: 100%;
  height: 300px;
  padding: 20px 0;

  .getCodeBtn {
    display: block;
    margin: 0 auto;
  }

  img {
    display: block;
    width: 300px;
    height: 300px;
    margin: 0 auto;
  }
}